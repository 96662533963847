/**
 * Animate paths in SVGs
 *
 * Usage:
 * add attribute `data-animate="svg"` on an svg or img element containing an svg
 * and optional data-attributes, see example below.
 * Animation starts as soon as the element comes into view.
 *
 * @example
    data-animation-delay="50" // delay in which the animation should start on viewport hit
    data-type="sync" // 'sync'|'delayed'|'oneByOne'
    data-duration="200" // frames
    data-delay="20" // frames for 'delayed' type
    data-dash-gap="2" // space between dashes
 */
export async function animateSVG() {
    const animatableSVGs = document.querySelectorAll('[data-animate="svg"]');

    if (animatableSVGs && animatableSVGs.length) {
        let { initAnimateSVG } = await import(/* webpackMode: "lazy" */ './animate-svg.async.js');

        initAnimateSVG(animatableSVGs);
    }
}
