/**
 * Sets CSS variable for correct 100vh usage
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export function fullHeightOnMobile() {
    let prevWidth = window.innerWidth;

    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    window.addEventListener(
        'resize',
        () => {
            if (prevWidth !== window.innerWidth) {
                prevWidth = window.innerWidth;
                document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
            }
        },
        { passive: true },
    );
}
