export async function validation() {
    let $forms = $('form')
        .not('[novalidate]:not(.wpcf7-form):not(.woocommerce-checkout):not(.comment-form)')
        .not('.cart')
        .not('.woocommerce-cart-form')
        .not('.woocommerce-ordering')
        .not('.woocommerce-shipping-calculator')
        .not('.shop__add-to-cart__form')
        .not('.woocommerce-form-coupon')
        .not('.checkout-coupon');

    if ($forms.length) {
        let { initValidation } = await import(/* webpackMode: "lazy" */ './inc/validation.async.js');

        return initValidation($forms);
    }
}
