export function serviceWorker({ register = false, unregister = true } = {}) {
    // handle service workers only in production mode
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    if ('serviceWorker' in navigator === false) {
        return;
    }

    if (unregister !== true && register === true) {
        window.addEventListener(
            'load',
            () => {
                navigator.serviceWorker.register('/service-worker.js');
            },
            { passive: true },
        );
    } else if (unregister !== false) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let worker of registrations) {
                worker.unregister();
            }
        });
    }
}
