async function manageCookieConsent() {
    if (CONFIG.cookieConsent === false) {
        const { initBorlabsCookieA11yFixes } = await import(/* webpackMode: "lazy" */ './borlabs/index.js');

        initBorlabsCookieA11yFixes();
    } else {
        const { cookieInfoAlert } = await import(/* webpackMode: "lazy" */ './cookieconsent/index.js');

        cookieInfoAlert();
    }
}

export { manageCookieConsent };
